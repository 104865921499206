import React from "react";
import Layout from "../components/common/layout/layout";
import SEO from "../components/common/layout/seo";
import Navigation from "../components/common/navigation/navigation-new";
import { Container } from "../components/global";
import Header from "../components/sections/security/header";
import Footer from "../components/sections/footer/index-new";
import { TextContent, Section, P, H1, H2, UL, OL } from "../styles/TCandPrivacyStyles";

const PrivacyPolicy = () => (
  <Layout>
    <SEO title="Privacy Policy" />
    <Navigation background="white" />
    <Header {...pageData.headerData} />
    <Container style={{ width: "100%", maxWidth: "100vw", padding: "0", background: "rgb(244, 252, 250);" }}>
      <TextContent>
        <Section>
          <H1>Safety Information</H1>
          <H2>Safeguard Your Account</H2>
          <P fontSize={["12px", null, null, "20px", "20px"]}>
            Safeguard your account as your personal property; refrain from sharing card information, login or token via
            phone, SMS, or email. OPay Digital Services Limited is committed to enhancing platform security, ensuring
            your online safety, and maintaining customer account confidentiality. Learn more about online protection
            measures, including identifying fake emails and websites.
          </P>
        </Section>
        <Section>
          <H1>Security Tips</H1>
          <UL>
            <li>
              Avoid sharing your account login, card details, and PIN to prevent unauthorized access. OPay Digital
              Services never requests such information through any communication channel.
            </li>
            <li>
              Keep your ATM cards secure and refrain from disclosing your PIN. Do not maintain a written duplicate of
              your PIN.
            </li>
            <li>
              Recognizing a familiar phone number or email doesn't guarantee authenticity; be cautious with
              cold-callers.
            </li>
            <li>
              Hide your PIN when using Automated Teller Machines (ATMs )or making app transactions by using your hand or
              body.
            </li>
            <li>Create very strong and robust passwords for your app login and card PIN. Change them regularly.</li>
            <li>
              Review your transaction history regularly to ensure it only includes your transactions; please report any
              unexplained transactions.
            </li>
            <li>Enable app notifications for timely updates on account activities sent to your cell phone or email.</li>
            <li>Access our services exclusively through your Opay mobile app for added security.</li>
            <li>
              Exercise caution against copycat websites or applications resembling OPay's; be wary of fake sites or apps
              that mimic our platform.
            </li>
          </UL>
          <H1>Recognizing Fraud</H1>
          <P>
            You are the first and most effective line of defense in combatting online fraud. Learning how to identify
            and prevent online fraud is the most efficient protection against fraud. Read the following tips to help you
            recognize potential scams. Online fraud typically takes the form of fraudulent e-mails and Web sites. These
            forged means of communication often use corporate logos, colors and legal disclaimers to make them appear
            authentic.
          </P>
          <P>
            <span>Fraudulent Emails</span>
          </P>
          <P>
            Online scam through fraudulent emails is a very popular form of deception. A "spoofed" email mimics a
            legitimate source, aiming to deceive you into divulging personal or account information, making payments, or
            engaging in fraudulent activities. These attacks persist due to their low-tech nature, allowing for
            large-scale execution. Despite visible warning signs, individuals still succumb to "phishing" and scam
            emails, some of which prompt customers to update their accounts by clicking on links leading to counterfeit
            websites. Stay vigilant against these tactics and do not fall victim.
          </P>
          <P>
            <span>Spoofed Websites</span>
          </P>
          <P>
            Spoofed Web sites, like phishing e-mails, are used by fraudsters who build fake websites that look very
            similar to OPay Digital Services Limited’s website to lure unsuspecting customers into providing their
            online banking log-in information and card details which are later used to access such accounts. Spoof Web
            sites allow fraudsters to collect such sensitive information as Account Information and Card Details. OPay
            Digital Services Ltd will never ask a customer to provide, verify or update their personal, account or
            financial information via email or pop-up windows. This includes: Passwords, Personal Identification Numbers
            (PIN), or ATM, Debit Card numbers. If you receive an email requesting such information, do not respond and
            never click on a link contained in a suspicious email.
          </P>
          <P>
            <span>Phishing Calls or Emails</span>
          </P>
          <P>
            Phishing involves deceptive tactics like false calls, emails, or browser pop-ups that mimic a legitimate
            source, often featuring a company's name, logo, or graphic. A common scam scenario includes;
            <br />- Receiving a call or an email asking you to update or validate your account details.
            <br />- The message warns that if you fail to respond, your account access will expire or be suspended.
            <br />- A link in the message sends you to a website that appears to be legitimate, but is not.
            <br />
            The scammers aim to trick you into revealing personal information, such as your account number, PIN, or
            password, enabling them to commit financial crimes or identity theft. It could also be an attempt to
            compromise your phone or device security. Remain cautious and vigilant against these deceptive
          </P>
          <P>
            <span>practic.How to avoid falling for Phishing Scams</span>
          </P>
          <P>
            Never open any email unless you know who the sender is. Just opening an email can introduce malware to your
            device. Be skeptical of every email you get, and never click on suspicious links, or download suspicious
            attachments. In case of uncertainty, please call OPay's customer service.
          </P>
        </Section>

        <Section>
          <H1>Pharming</H1>
          <P>
            This occurs when you enter a Web address but are redirected, without your consent or knowledge to a
            fraudulent site that looks similar to a legitimate site. These fraudulent sites are created to capture
            confidential information.
          </P>
          <UL style={{ paddingLeft: "20px" }}>
            <li>
              If your phone is lost or stolen, use this code to block your account <span>*955*131#</span> and contact us
              immediately using the following telephone numbers: <span>0700 8888 328</span> or{" "}
              <span>020 18888 328</span>
            </li>
            <li>
              If your card is lost or stolen, use this code to block your card <span>*955*132#</span> and contact us
              immediately using the following telephone numbers: <span>0700 8888 328</span> or{" "}
              <span>020 18888 328</span>
            </li>
            <li>
              You will never be contacted directly by companies like Verve to verify personal or card information, your
              PIN or to request that you transfer funds or process transactions to protect your account.
            </li>
            <li>
              Memorize your Personal Identification Number (PIN). Never write it on the card or anywhere else it could
              be compromised.
            </li>
          </UL>
        </Section>

        <Section>
          <H1>Skimming</H1>
          <P>
            “Skimming“ is a technique where thieves capture the magnetic stripe data from your card to produce a
            counterfeit card. These fake cards are then used to process unauthorized transactions against your account.
            There are two main methods of skimming card information:
            <br />A small device that seems to be part of the machine is placed over the card insertion hole of an
            ATM or other self-service kiosk. As you insert your card into the ATM, the device "reads" the data on the
            stripe and either stores it or sends it to a nearby site. Sometimes, there is a small, hidden camera that
            records your keystrokes as you enter your PIN into the machine.
          </P>
          <P>
            <span>How to protect yourself</span>
            <UL style={{ paddingLeft: "20px" }}>
              <li>
                If you see an attachment on an ATM that looks suspicious, don’t use the ATM. Notify the institution that
                owns the machine as soon as possible.
              </li>
              <li>Never give your PIN to anyone or write it on your card.</li>
              <li>
                Review your transaction history immediately and notify us of any discrepancy via our in-app customer
                service feature or by calling us on <span>0700 8888 328</span> or <span>020 18888 328</span>.
              </li>
            </UL>
          </P>
        </Section>

        <Section>
          <H1>Your Responsiblity</H1>
          <P>
            OPay Digital Services, its staff or agents will never contact you via phone call or email with a link
            requesting you to update your account or your passwords, token generated codes, card details or PIN. Anyone
            soliciting such information is a fraudster.
            <br />
            If you receive an email or encounter a suspicious telephone call fitting this description, please report it
            through one of the following channels:
          </P>
          <UL>
            <li>
              <span>In-App Customer Service:</span>
            </li>
            <P>
              For assistance or inquiries, kindly utilize the self-service or live chat feature within the app to
              connect with a customer service representative.
            </P>
            <li>
              <span>Mobile Complaints:</span>
            </li>
            <P>
              - Call us on <span>0700 888 8328</span> or <span>020 18888 328</span> (for App & Card queries).
              <br />- Call us on <span>0700 888 8329</span> or <span>020 18888 329</span> (for POS Business queries).
              <br />
              Our helpline is available 24/7.
              <br />
              Alternatively, chat with us on WhatsApp at <span>+2349165998936</span>.
            </P>
          </UL>
        </Section>

        <Section>
          <H1>OPay's Polic</H1>
          <P>
            OPay Digital Services Limited is committed to constant innovation and the implementation of security
            features to uphold the integrity of our platform. Our primary objective is to safeguard the confidentiality
            of customer accounts and personal data. While we strive to provide a secure transaction environment,
            customers should take the following steps to protect confidential information during financial transactions
            on the app::
          </P>
          <UL style={{ paddingLeft: "20px" }}>
            <li>
              Create a strong unique account password. Select a password that is hard to guess by using random letters,
              numbers, and symbols. Do not use readily identifiable information such as your name, birth date, or
              child's name.
            </li>
            <li>Do not share your password with anyone else. Keep your password confidential.</li>
            <li>Change your PIN regularly. We recommend changing your PIN every 60 to 90 days.</li>
            <li>Close your app window when you have finished your app session for added security.</li>
          </UL>
        </Section>
      </TextContent>
    </Container>
    <Footer />
  </Layout>
);

export default PrivacyPolicy;

const pageData = {
  headerData: {
    captionText: "Privacy Policy",
    subTitleText: "Version 7.0 | April 2, 2024",
  },
};
