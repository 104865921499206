import React from "react";
import { Container } from "../../../global";
import { graphql, useStaticQuery } from "gatsby";
import HeroSection from "../../hero";

import { StyledBackgroundSection, HeaderWrapper, HeaderTextGroup, Flex } from "./style";

const Header = ({ captionText, subTitleText }) => {
  const data = useStaticQuery(graphql`
    query {
      csrBg: file(sourceInstanceName: { eq: "product" }, name: { eq: "index" }) {
        childImageSharp {
          fluid(maxWidth: 2880) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);
  return (
    <StyledBackgroundSection>
      <HeaderWrapper id="top">
        <Container>
          <Flex>
            <HeaderTextGroup>
              {/* <HeroSection.Title textAlign="center">{captionText}</HeroSection.Title>
              {subTitleText ? (
                <HeroSection.Subtitle width={1} textAlign={["center", "center"]}>
                  {subTitleText}
                </HeroSection.Subtitle>
              ) : null} */}
            </HeaderTextGroup>
          </Flex>
        </Container>
      </HeaderWrapper>
    </StyledBackgroundSection>
  );
};

Header.defaultProps = {
  captionText: "",
  subTitleText: "",
};

export default Header;
